import React, { useState, useRef, useEffect } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useDispatch } from "react-redux"
import { FormGroup, ButtonGroup, Button } from "shards-react"
import Header from "../../../components/widget/widgetTitle"
import { Loading } from "../../../components/exceptions/Loading"
import { documentChecklistActions } from ".."
import { modalActions } from "../../modals/_actions"

export const UploadApplicationChecklist = ({ modalID, data }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [dragStatus, setDragStatus] = useState("Drag a photo here")
  const [uploadFile, setUploadFile] = useState("")
  const [error, setError] = useState(false)
  const imageInputEl = useRef(null)

  // Handle the upload process
  const uploadApplicationChecklist = async () => {
    setIsLoading(true)
    setError(false)
    if (uploadFile) {
      await dispatch(
        documentChecklistActions.uploadApplicationChecklist({
          applicationId: data.applicationId,
          checklistId: data.id,
          file: uploadFile,
        })
      )
      closeModal()
    } else {
      setError(true)
      setDragStatus("No file selected")
    }
    setIsLoading(false)
    return
  }

  useEffect(() => {
    /*
      prevent browser loading local file as new page
      when user drops file anywhere on window.
      */
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault()
      },
      false
    )

    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault()
      },
      false
    )
  })

  // monitor dragging and droping file
  const onDragEnter = (event) => {
    setDragStatus("File Detected")
    event.preventDefault()
    event.stopPropagation()
  }

  const onDragLeave = (event) => {
    setDragStatus("Drop Here")
    event.preventDefault()
  }

  const onDragOver = (event) => {
    event.preventDefault()
  }

  const onDrop = (event) => {
    event.preventDefault()
    const fileData = fileValidation(event.dataTransfer.files[0])
    setUploadFile(fileData)
  }

  const onImageChange = (e) => {
    e.preventDefault()
    setError(false)
    let fileData
    if (e.target.files[0]) {
      fileData = fileValidation(e.target.files[0])
    } else {
      fileData = ""
      setUploadFile("")
      setDragStatus("No file selected")
    }
    return fileData
  }

  // Check if file is valid
  const fileValidation = (file) => {
    const supportedFilesTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ]
    setUploadFile(file)
    let { type } = file
    if (supportedFilesTypes.indexOf(type) > -1) {
      if (file.size < 2097152) {
        return file
      } else {
        setError(true)
        setDragStatus("Please upload an Image file less than 2MB")
      }
    } else {
      setError(true)
      setDragStatus("Please enter an Image file")
      return
    }
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal">
      <Header
        title={"Upload"}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <FormGroup>
              <label htmlFor="image">Upload Image</label>
              <div
                className={`image-upload_container ${
                  uploadFile && !error && "active-upload"
                } ${error && "error-upload"}`}
                onDragEnter={(event) => onDragEnter(event)}
                onDragLeave={(event) => onDragLeave(event)}
                onDragOver={(event) => onDragOver(event)}
                onDrop={(event) => onDrop(event)}
              >
                <div className="drag-photo_text">{dragStatus}</div>
                <div className="or_text">Or</div>
                <button
                  className="upload-image_button"
                  type="button"
                  onClick={() => imageInputEl.current.click()}
                >
                  <input
                    type="file"
                    ref={imageInputEl}
                    name="image"
                    onChange={onImageChange}
                    className="image-input"
                  />
                  Select photos
                </button>
                <div>{uploadFile && uploadFile.name}</div>
              </div>
            </FormGroup>
            <ButtonGroup className="btn-group-actions site-modal__actions">
              <Button
                theme="primary"
                className="thin mr-2"
                onClick={() => uploadApplicationChecklist()}
                disabled={(error || !uploadFile) && "disabled"}
              >
                Save
              </Button>
              <Button
                theme="secondary"
                className="thin delete"
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </>
        )}
      </div>
    </div>
  )
}
