export const reportsConstants = {
  REPORTS_GET_REQUEST: "REPORTS_GET_REQUEST",
  REPORTS_GET_SUCCESS: "REPORTS_GET_SUCCESS",
  REPORTS_GET_FAILURE: "REPORTS_GET_FAILURE",

  REPORTS_FILTER_REQUEST: "REPORTS_FILTER_REQUEST",
  REPORTS_FILTER_SUCCESS: "REPORTS_FILTER_SUCCESS",
  REPORTS_FILTER_FAILURE: "REPORTS_FILTER_FAILURE",

  REPORTS_DATE_INPUTS: "REPORTS_DATE_INPUTS",
  SELECTED_REPAYMENT_REPORTS_DATE_INPUTS:
    "SELECTED_REPAYMENT_REPORTS_DATE_INPUTS",

  REPORTS_EXPORT_REQUEST: "REPORTS_EXPORT_REQUEST",
  REPORTS_EXPORT_SUCCESS: "REPORTS_EXPORT_SUCCESS",
  REPORTS_EXPORT_FAILURE: "REPORTS_EXPORT_FAILURE",

  REPORTS_QUEUE_REQUEST: "REPORTS_QUEUE_REQUEST",
  REPORTS_QUEUE_SUCCESS: "REPORTS_QUEUE_SUCCESS",
  REPORTS_QUEUE_FAILURE: "REPORTS_QUEUE_FAILURE",

  REPORTS_DOWLOAD_REQUEST: "REPORTS_DOWLOAD_REQUEST",
  REPORTS_DOWLOAD_SUCCESS: "REPORTS_DOWLOAD_SUCCESS",
  REPORTS_DOWLOAD_FAILURE: "REPORTS_DOWLOAD_FAILURE",

  REPORTS_HIERARCHY_INPUTS: "REPORTS_HIERARCHY_INPUTS",

  HIERARCHY_GET_REQUEST: "HIERARCHY_GET_REQUEST",
  HIERARCHY_GET_SUCCESS: "HIERARCHY_GET_SUCCESS",
  HIERARCHY_GET_FAILURE: "HIERARCHY_GET_FAILURE",

  REPORTS_MFI_ID: "REPORTS_MFI_ID",
  REPORTS_SUBMITTED_BY: "REPORTS_SUBMITTED_BY",

  DEFAULT_REPORTS_REQUEST: "DEFAULT_REPORTS_REQUEST",
  DEFAULT_REPORTS_SUCCESS: "DEFAULT_REPORTS_SUCCESS",
  DEFAULT_REPORTS_FAILURE: "DEFAULT_REPORTS_FAILURE",

  EXPORT_DEFAULT_REPORTS_REQUEST: "EXPORT_DEFAULT_REPORTS_REQUEST",
  EXPORT_DEFAULT_REPORTS_SUCCESS: "EXPORT_DEFAULT_REPORTS_SUCCESS",
  EXPORT_DEFAULT_REPORTS_FAILURE: "EXPORT_DEFAULT_REPORTS_FAILURE",

  EXPORT_REPAYMENTS_REPORTS_REQUEST: "EXPORT_REPAYMENTS_REPORTS_REQUEST",
  EXPORT_REPAYMENTS_REPORTS_SUCCESS: "EXPORT_REPAYMENTS_REPORTS_SUCCESS",
  EXPORT_REPAYMENTS_REPORTS_FAILURE: "EXPORT_REPAYMENTS_REPORTS_FAILURE",

  SUMMARY_REPORTS_EXPORT_REQUEST: "SUMMARY_REPORTS_EXPORT_REQUEST",
  SUMMARY_REPORTS_EXPORT_SUCCESS: "SUMMARY_REPORTS_EXPORT_SUCCESS",
  SUMMARY_REPORTS_EXPORT_FAILURE: "SUMMARY_REPORTS_EXPORT_FAILURE",

  PAYMENTS_REPORT_REQUEST: "PAYMENTS_REPORT_REQUEST",
  PAYMENTS_REPORT_SUCCESS: "PAYMENTS_REPORT_SUCCESS",
  PAYMENTS_REPORT_FAILURE: "PAYMENTS_REPORT_FAILURE",
}
