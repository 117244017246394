export default function () {
  return [
    {
      title: "Pages",
      open: false,
      items: [
        {
          title: "Client",
          name: "client",
          icon: "client-icon.svg",
          to: "/clients",
        },
        {
          title: "Applications",
          name: "applications",
          icon: "icon-funnel.svg",
          to: "/applications",
          headerMenus: [
            {
              title: "Summary",
              name: "summary",
              to: "/applications",
            },
            {
              title: "Create Application",
              name: "createApplication",
              to: "/applications/add",
            },
          ],
        },
        {
          title: "Purchases",
          name: "payments",
          icon: "purchase-tag.svg",
          to: "/payments",
          headerMenus: [
            {
              title: "Payment",
              name: "payments",
              to: "/payments",
            },
          ],
        },
        {
          title: "New Groups",
          name: "groups",
          icon: "groups-tag.svg",
          to: "/groups",
          headerMenus: [
            {
              title: "Groups",
              name: "groups",
              to: "/groups",
            },
          ],
        },
        {
          title: "Collections",
          name: "collections",
          icon: "collections-tag.svg",
          to: "/collections",
          headerMenus: [
            {
              title: "Collections",
              name: "collections",
              to: "/collections",
            },
          ],
        },
        {
          title: "Village Banks",
          name: "villageBanks",
          icon: "purchase-tag.svg",
          to: "/villageBanks",
          headerMenus: [
            {
              title: "Village Bank",
              name: "villageBanks",
              to: "/villageBanks",
            },
          ],
        },

        {
          title: "Scoring",
          name: "scoring",
          icon: "score-icon.svg",
          to: "/credit-score",
        },
        {
          title: "Configuration",
          name: "configuration",
          icon: "configuration-sidebar-icon.svg",
          to: "/config",
        },
        {
          title: "Users",
          name: "users",
          icon: "approval-icon.svg",
          to: "/users",
          headerMenus: [
            {
              title: "Summary",
              name: "summary",
              to: "/users",
            },
            {
              title: "Add User",
              name: "addUser",
              to: "/users/add",
            },
          ],
        },
        {
          title: "Settings",
          name: "settings",
          icon: "settings-icon.svg",
          to: "/settings",
        },
        {
          title: "Reports",
          name: "reports",
          icon: "reports-icon.svg",
          to: "/reports/overview",
          subMenus: [
            {
              title: "Applications",
              name: "applications",
              to: "/reports/overview",
            },
            {
              title: "Payments",
              name: "paymentsReport",
              to: "/reports/paymentsReport",
            },
            {
              title: "Repayment",
              name: "repayments",
              to: "/reports/repayments",
            },
            {
              title: "Impact",
              name: "impact",
              to: "/reports/impact",
            },
            {
              title: "Summary",
              name: "applicationsSummary",
              to: "/reports/summary",
            },
            {
              title: "Report Queue",
              name: "reportQueue",
              to: "/reports/queue",
            },
          ],
        },
        {
          title: "MFI Setup",
          name: "mfiSetup",
          icon: "mfi-setup-icon.svg",
          to: "/mfis",
          headerMenus: [
            {
              title: "Summary",
              name: "summary",
              to: "/mfis",
            },
            {
              title: "Add MFI",
              name: "addMfi",
              to: "/mfis/add",
            },
          ],
        },
        {
          title: "Tools",
          name: "tools",
          icon: "tools-icon.svg",
          to: "/tools/export",
          subMenus: [
            {
              title: "Import",
              name: "import",
              to: "/tools/import",
            },
            {
              title: "Export",
              name: "export",
              to: "/tools/export",
            },
            {
              title: "Bulk Initialise",
              name: "bulkInitialise",
              to: "/tools/bulkInit",
            },
            {
              title: "Sync Integration",
              name: "syncExternalIntegration",
              to: "/tools/integration",
            },

            {
              title: "Raster Files",
              name: "rasterFiles",
              to: "/tools/upload-raster-file",
            },
          ],
        },
        {
          title: "Tools",
          name: "tool",
          icon: "tools-icon.svg",
          to: "/tool/integration",
          subMenus: [
            {
              title: "Sync Integration",
              name: "syncExternalIntegration",
              to: "/tool/integration",
            },
            {
              title: "Audit trail",
              name: "Audittrail",
              to: "/tools/Audit",
            },
          ],
        },
      ],
    },
  ]
}
