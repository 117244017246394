import React, { createContext, useEffect } from "react"
import SockJS from "sockjs-client"
import { Stomp } from "@stomp/stompjs"
import { notificationsActions } from "../client/views/notifications/store/_actions"
import { useDispatch, useSelector } from "react-redux"

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {
  const user = useSelector((state) => state.authentication)

  const { mfi = {} } = user && user?.profile
  const dispatch = useDispatch()

  const fetchAgain = () => {
    if (mfi !== null) {
      const mfiId = mfi && mfi?.id

      if (mfiId !== undefined) {
        dispatch(notificationsActions.fetchNotifications(mfiId))
      }
    }
  }

  const token = JSON.parse(localStorage.getItem("user"))
  // loan officer token - the one linked to loan arrears

  useEffect(() => {
    if (token) {
      if (token.accessTokenString !== undefined) {
      }
    }
  }, [token, dispatch])

  return <WebSocketContext.Provider>{children}</WebSocketContext.Provider>
}
