import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AgGridReact } from "ag-grid-react"
import { Row, Container } from "shards-react"
import { useTranslation } from "react-i18next"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import PageTitle from "../../../components/common/PageTitle"
import { reportsActions } from "../_actions"
import "./Overview.scss"
import { LoadingView } from "../../../views"

export const ReportQueue = () => {
  const { loadingQueue, reportsQueue } = useSelector((state) => state.reports)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(reportsActions.getReportQueue())
  }, [dispatch])

  const downloadLinkRenderer = (params) => {
    return (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        {params.value}
      </a>
    )
  }

  const columnDefs = [
    {
      headerName: "Created At",
      field: "createdDate",
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString()
      },
    },
    { headerName: "Status", field: "status" },
    { headerName: "Username", field: "username" },
    {
      headerName: "Download Link",
      field: "downloadLink",
      cellRenderer: downloadLinkRenderer,
      flex: 1,
    },
  ]

  const data = reportsQueue.map((entity) => ({
    createdDate: entity.createdDate,
    status: entity.status,
    username: entity.username,
    downloadLink: entity.downloadLink,
  }))

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("module.report.reportQueue")}
          subtitle={t("module.report.dashboard")}
          className="text-sm-left mb-3"
        />
      </Row>
      {loadingQueue ? (
        <LoadingView />
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%" }}
        >
          <AgGridReact
            rowData={data}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            suppressRowClickSelection={true}
          />
        </div>
      )}
    </Container>
  )
}
