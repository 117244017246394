import {
  axiosInstance,
  handleResponse,
  clientInstance,
  integrationsInstance,
} from "../../_helpers/base.service"
const serviceGroup = "workflow"

/**
 * Export functions as in one module
 */
export const reportsService = {
  filterReports,
  exportReports,
  exportApplicationsReports,
  getHierarchy,
  repaymentReports,
  exportDefaultReports,
  exportRepaymentsReports,
  exportSummaryReports,
  getPaymentsSummaryReport,
  filterPaymentSummaryReport,
  filterGeneralPaymentSummaryReport,
  downloadMusoniReport,
  getReportQueue,
}

/**
 * filter reports
 *
 * @param {*} data object
 */
function filterReports(data) {
  return axiosInstance
    .post(`${serviceGroup}/reports/survey-applications`, data)
    .then(handleResponse)
}

/**
 * export reports
 *
 * @param {*} data object
 */
function exportReports(data) {
  return axiosInstance
    .post(`${serviceGroup}/export/applications-report`, data, {
      responseType: "blob",
      timeout: 30000,
    })
    .then((res) => res)
}

/**
 * download reports
 *
 */
function downloadMusoniReport(username) {
  return integrationsInstance
    .get("musoni/exposed/musoni-report-download", {
      responseType: "blob",
      timeout: 30000,
      params: { username },
    })
    .then((res) => res)
}

/**
 * export applications reports
 *
 * @param {*} data object
 */
function exportApplicationsReports(data) {
  return axiosInstance
    .post(`${serviceGroup}/export/filtered-applications`, data)
    .then(handleResponse)
}

/**
 * export reports queuw
 *
 */
function getReportQueue(data) {
  return axiosInstance
    .get(`${serviceGroup}/export/report-queue`)
    .then(handleResponse)
}

/**
 * export summary reports
 *
 * @param {*} data object
 */
function exportSummaryReports(data) {
  return axiosInstance
    .post(`${serviceGroup}/export/summary-filtered-applications`, data, {
      responseType: "blob",
      timeout: 30000,
    })
    .then((res) => res)
}

/**
 *
 * @param {*} mfiId
 */
function getHierarchy(mfiId) {
  return axiosInstance
    .get(
      `${serviceGroup}/hierarchy/applications-filter-hierarchy/?mfiId=${mfiId}`
    )
    .then(handleResponse)
}

/**
 *
 * GET Repayments reports
 *
 */
function repaymentReports(payload) {
  return clientInstance.post(`reports/defaults`, payload).then(handleResponse)
}

/**
 *
 * POST export default Repayments reports
 *
 */
function exportDefaultReports(payload) {
  return clientInstance
    .post(`reports/defaults/export`, payload, {
      responseType: "blob",
      timeout: 30000,
    })
    .then((res) => res)
}

/**
 *
 * POST export Repayments reports
 *
 */
function exportRepaymentsReports(payload) {
  return clientInstance
    .post(`reports/repayment/export`, payload, {
      responseType: "blob",
      timeout: 30000,
    })
    .then((res) => res)
}

/**
 *
 * GET payments summary reports
 *
 */
function getPaymentsSummaryReport() {
  return integrationsInstance
    .get(`musoni/payments/list-purchase-summary-report`)
    .then(handleResponse)
}

/**
 *
 * FILTER payments summary reports
 *
 */
function filterPaymentSummaryReport(startDate, endDate) {
  return integrationsInstance
    .get(
      `musoni/payments/filter-purchase-summary-report?startDate=${startDate}&endDate=${endDate}`
    )
    .then(handleResponse)
}

/**
 *
 * FILTER payments summary reports - General
 *
 */
function filterGeneralPaymentSummaryReport(filters) {
  let filterQuery = "filters="
  filters.forEach((filter) => {
    filterQuery = `${filterQuery}${filter},`
  })
  return integrationsInstance
    .get(
      `musoni/payments/general-filter-purchase-summary-report?${filterQuery}`
    )
    .then(handleResponse)
}
