import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "shards-react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Wizard, Steps, Step } from "react-albus"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Preloader, Placeholder } from "react-preloading-screen"
import { isEmpty } from "lodash"

import { withTranslation } from "react-i18next"
import { applicationActions } from "../../applicationManager"
import { SetQuestionnaire } from "../../scoreLogic/views/SetQuestionnaire"
import { ApplicationColumns } from "./ApplicationColumns"
import { ReportColumns } from "./ReportColumns"
import { ReportDetails } from "./ReportDetails"
import { Navigation } from "../component/Navigation"
import { Heading } from "../component/Heading"
import { WizardActions } from "../component/WizardActions"
import { configActions } from ".."
import { cslActions } from "../../scoreLogic"
import PageTitle from "../../../components/common/PageTitle"
import { hasAuthority } from "../../../_helpers" // Importing the hasAuthority helper

import "./Config.scss"
import "./Animations.scss"
import "./Navigation.scss"
import { CreditScoreReport } from "./CreditScoreReport"
import { LoanDetails } from "./LoanDetails"
import { FirstComment } from "./FirstComment"
import { CustomerProfile } from "./customerProfile"

const Config = ({ surveys, config, scoreLogic, dispatch, t }) => {
  const { uid, computationCodes } = scoreLogic
  const { assets } = surveys
  const [questionnaireName, setQuestionnaireName] = useState("")
  const [point, setPoint] = useState(1)

  const noComputationCodes = isEmpty(computationCodes)

  useEffect(() => {
    if (!uid) return

    dispatch(cslActions.getComputationCodes(uid))
    dispatch(configActions.getApplicationColumns(uid))
  }, [uid, dispatch])

  useEffect(() => {
    if (!uid) return

    if (hasAuthority("MFI_ADMIN")) {
      dispatch(cslActions.getComputationCodes(uid))
      dispatch(configActions.getReportColumns(uid))
    }
  }, [uid, dispatch])

  const getQuestionnaire = useCallback(() => {
    if (!uid) return
    const [xformId] = uid.split(":")

    if (assets) {
      assets.forEach((asset) => {
        if (asset.uid === xformId) {
          setQuestionnaireName(asset.name)
        }
      })
    }
  }, [uid, assets])

  useEffect(() => {
    getQuestionnaire()
  }, [getQuestionnaire])

  // Define the titles for all steps
  const titles = {
    setQuestionaire: t(
      "module.scoreLogic.view.configuration.titles.setQuestionnaire"
    ),
    applicationColumns: t(
      "module.scoreLogic.view.configuration.titles.applicationColumns"
    ),
    reportColumns: t(
      "module.scoreLogic.view.configuration.titles.reportColumns"
    ),
    profileFields: t(
      "module.scoreLogic.view.configuration.titles.profileFields"
    ),
    cbsFields: t("module.scoreLogic.view.configuration.titles.cbsFields"),
    creditScoreReport: t(
      "module.scoreLogic.view.configuration.titles.creditScoreReport"
    ),
    loanDetails: t("module.scoreLogic.view.configuration.titles.loanDetails"),
    firstComment: t("module.scoreLogic.view.configuration.titles.firstComment"),
    impactreportDetails: t(
      "module.scoreLogic.view.configuration.titles.impactreportDetails"
    ),
    customerProfile: t(
      "module.scoreLogic.view.configuration.titles.customerProfile"
    ),
  }

  if (hasAuthority("MFI_ADMIN")) {
    delete titles.applicationColumns
    delete titles.profileFields
    delete titles.cbsFields
    delete titles.creditScoreReport
    delete titles.loanDetails
    delete titles.firstComment
    delete titles.impactreportDetailsreportDetails
    delete titles.customerProfile
  }

  const classes = uid
    ? "setup-questionnaire-complete"
    : "setup-questionnaire-incomplete"

  return (
    <Preloader>
      <Container fluid className="main-content-container px-4">
        <CssBaseline />
        <Row noGutters className="page-header pt-4 pb-1">
          <PageTitle
            sm="9"
            title={questionnaireName}
            className="text-sm-left"
          />
        </Row>
        <Row noGutters className="page-header py-1">
          <Col
            md="12"
            lg="10"
            className="mx-auto mt-4 score-logic relative h-full"
          >
            <Wizard
              render={({ step }) => (
                <>
                  <Heading titles={titles} point={point} />
                  <div className={`flex bg-white p-2 ${classes}`}>
                    {uid && <Navigation titles={titles} step={step} />}
                    <TransitionGroup className="score-logic-setup-wrapper h-full">
                      <CSSTransition
                        key={step.id}
                        className="score-logic-setup"
                        timeout={{ enter: 500, exit: 0 }}
                      >
                        <div className="score-logic-setup-steps fluid">
                          {(hasAuthority("FA_ANALYST") ||
                            hasAuthority("FA_ADMIN")) && (
                            <Steps step={step}>
                              <Step
                                id="setQuestionaire"
                                render={() => (
                                  <div className="content">
                                    <SetQuestionnaire
                                      desc={t(
                                        "module.scoreLogic.view.configuration.titles.questionnaireDescription"
                                      )}
                                    />
                                  </div>
                                )}
                              />
                              <Step
                                id="applicationColumns"
                                render={() => (
                                  <div className="content">
                                    <ApplicationColumns />
                                  </div>
                                )}
                              />
                              <Step
                                id="profileFields"
                                render={() => (
                                  <div className="content">
                                    <ApplicationColumns isProfileView />
                                  </div>
                                )}
                              />
                              <Step
                                id="cbsFields"
                                render={() => (
                                  <div className="content">
                                    <ApplicationColumns isCbsView />
                                  </div>
                                )}
                              />
                              <Step
                                id="creditScoreReport"
                                render={() => (
                                  <div className="content">
                                    <CreditScoreReport />
                                  </div>
                                )}
                              />
                              <Step
                                id="loanDetails"
                                render={() => (
                                  <div className="content">
                                    <LoanDetails />
                                  </div>
                                )}
                              />
                              <Step
                                id="impactreportDetails"
                                render={() => (
                                  <div className="content">
                                    <ReportDetails />
                                  </div>
                                )}
                              />
                              <Step
                                id="firstComment"
                                render={() => (
                                  <div className="content">
                                    <FirstComment />
                                  </div>
                                )}
                              />

                              <Step
                                id="customerProfile"
                                render={() => (
                                  <div className="content">
                                    <CustomerProfile />
                                  </div>
                                )}
                              />
                            </Steps>
                          )}
                          {hasAuthority("MFI_ADMIN") && (
                            <Steps step={step}>
                              <Step
                                id="setQuestionaire"
                                render={() => (
                                  <div className="content">
                                    <SetQuestionnaire
                                      desc={t(
                                        "module.scoreLogic.view.configuration.titles.questionnaireDescription"
                                      )}
                                    />
                                  </div>
                                )}
                              />
                              <Step
                                id="reportColumns"
                                render={() => (
                                  <div className="content">
                                    <ReportColumns />
                                  </div>
                                )}
                              />
                            </Steps>
                          )}
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                  <WizardActions
                    titles={titles}
                    step={step}
                    setPoint={setPoint}
                  />
                </>
              )}
            />
          </Col>
        </Row>
      </Container>
      <Placeholder>
        <span>{t("component.exceptions.loading.title")}...</span>
      </Placeholder>
    </Preloader>
  )
}

function mapStateToProps(state) {
  const { config, surveys, applications, reports, scoreLogic } = state
  return {
    config,
    surveys,
    applications,
    scoreLogic,
  }
}

const connectedConfig = withTranslation()(connect(mapStateToProps)(Config))
export { connectedConfig as Config }
