import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import { Dropdown, DropdownMenu } from "shards-react"
import { notificationsActions } from "../../../../modules/client/views/notifications/store/_actions"
import "./notification.scss"

const NotificationContainer = (props) => {
  const [count, setCount] = useState(0)
  const [notifications, setNotifications] = useState([])
  const { t } = useTranslation()
  const [isAnimating] = useState(false)
  const [mute, setMute] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const user = useSelector((state) => state.authentication)

  const { mfi } = user && user.profile
  const dispatch = useDispatch()
  const mfiId = mfi && mfi.id

  const fetchAgain = () => {
    if (mfiId !== undefined) {
      dispatch(notificationsActions.fetchNotifications(mfiId))
    }
  }

  useEffect(() => {
    if (!isEmpty(props.realtime)) {
      const { newNotifications } = props.realtime

      setCount(newNotifications)
    } else {
      setCount(props && notifications.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    if (props.notifications) {
      const notifications = props.notifications.filter(
        (item) => item.read === false
      )
      setNotifications(notifications)
      setCount(notifications.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notifications])

  const handleClick = () => {
    setMute(!mute)
  }
  const setDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <div
      className="notification-bar"
      style={{ margin: "auto", marginTop: "15px" }}
    >
      <Dropdown
        open={dropdownOpen}
        toggle={() => setDropdown()}
        group
        className="clientDetailsDropdown__dropdown-item"
      >
        <div
          style={{ margin: "auto", marginRight: "20px", marginBottom: "20px" }}
          onClick={() => {
            handleClick()
            setDropdown()
          }}
        >
          <Notification
            count={count}
            length={props && notifications.length}
            isAnimating={isAnimating}
            mute={mute}
          />
        </div>
        {count > 0 && (
          <DropdownMenu style={{ backgroundColor: "#60c1f7", width: "400px" }}>
            <div className="notification-wrapper">
              <div className="notification-header">
                <div className="notification-header-title">
                  {t("module.notifications.notifications")}(
                  {props && notifications.length})
                </div>
                {/* <div className="notification-header-close" toggle={() => this.setDropdownOpen(false)}>close</div> */}
              </div>
              {notifications &&
                notifications.slice(0, 7).map((item, i) =>
                  item.report ? (
                    <a
                      href={item.message}
                      className="notification-footer-title"
                      target="_blank"
                    >
                      {item.message}
                    </a>
                  ) : (
                    <div className="notification-messages" key={i}>
                      {item.message}
                    </div>
                  )
                )}
              <div className="notification-footer">
                <Link to="/clients" className="notification-footer-title">
                  {t("module.notifications.viewAllNotifications")}
                </Link>
                {props.roles && props.roles?.includes("MFI_LOAN_OFFICER") && (
                  <div
                    className="notification-footer-read"
                    onClick={() => props.markAllAsRead(fetchAgain)}
                  >
                    {t("module.notifications.markAllasRead")}
                  </div>
                )}
              </div>
            </div>
          </DropdownMenu>
        )}
      </Dropdown>
    </div>
  )
}

const NotificationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="28"
    viewBox="0 0 23 28"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="#185597">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M.595 23.056c0 .2.08.392.222.534.142.141.334.221.535.22h5.834c0 2.085 1.695 3.774 3.785 3.774s3.785-1.69 3.785-3.773h5.834c.419 0 .757-.338.757-.755s-.338-.754-.757-.754h-1.426v-9.795c-.004-3.403-2.121-6.448-5.316-7.646V3.283C13.848 1.7 12.56.416 10.97.416 9.382.416 8.095 1.7 8.095 3.283v1.578C4.9 6.06 2.783 9.104 2.779 12.507v9.795H1.352c-.2 0-.393.08-.535.22-.142.142-.222.334-.222.534zm10.376 3.019c-1.253-.001-2.27-1.014-2.27-2.264h4.541c-.001 1.25-1.017 2.263-2.27 2.264zM9.61 3.283c0-.75.61-1.358 1.362-1.358.753 0 1.363.608 1.363 1.358v1.172c-.902-.153-1.823-.153-2.725 0V3.283zm-5.316 9.224c.002-3.675 2.991-6.653 6.678-6.653s6.676 2.978 6.679 6.653v9.795H4.293v-9.795z"
                  transform="translate(-1163.000000, -18.000000) translate(244.000000, 0.000000) translate(916.000000, 18.000000) translate(-0.000000, 0.000000) translate(3.294118, 0.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const Notification = ({ count, isAnimating, length }) => (
  <div
    className={`${count && count > 0 ? "notification-bell" : ""} ${
      isAnimating ? "is-animating" : ""
    } `}
    data-count={count > 9 ? "9+" : count}
  >
    <NotificationIcon />
  </div>
)

export default NotificationContainer
